import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from "@/views/LoginView.vue";

// import getCompany from "@/services/getCompany";
// const {fetchCompany, data, error, loading} = getCompany()
import { useCookies } from "vue3-cookies";
import RecapitulationView from "@/views/RecapitulationView.vue";
import NotFound from "@/views/NotFound.vue";
// import {useStore} from "@/store/store";
const { cookies } = useCookies();


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomeView,
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginView
    },
    {
      path: '/recapitulation/:status',
      name: 'Recapitulation',
      component: RecapitulationView,
      props: true,
    },
    {
      path: '/:catchAll(.*)*',
      name: 'NotFound',
      component: NotFound
    },
  ]
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login',];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = cookies.get('user')

  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router
