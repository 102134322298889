<template>
  <AppLayout>
    <div class="rounded-lg bg-secondary-light sm:px-5 py-6 shadow sm:px-6 space-y-3">
      <div class="space-y-6">
        <template v-if="status === 'success'">
          <RecapitulationSuccess/>
        </template>
        <template v-else>
          <RecapitulationError/>
        </template>
      </div>
    </div>
  </AppLayout>

</template>

<script>
export default {
  name: "RecapitulationView"
}
</script>

<script setup>

import RecapitulationSuccess from "@/components/RecapitulationSuccess.vue";
import RecapitulationError from "@/components/RecapitulationError.vue";
import AppLayout from "@/components/AppLayout.vue";

defineProps(['status'])


</script>