<template>
  <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow ">
    <div class="px-4 py-5 sm:px-6">
      <h1 class="text-xl font-semibold text-gray-900">Kontakty</h1>
    </div>
    <div class="px-4 py-5">
      <dl class="sm:divide-y sm:divide-gray-200">
        <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
          <dt class="font-medium text-primary">Závozní dny</dt>
          <dd class="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
            <p v-for="day in delivery" :key="day.id">{{ day.nazev + ' ' + day.poznam }}</p>
          </dd>
        </div>
        <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
          <dt class="font-medium text-primary">Dotazy k objednávkám:</dt>
          <dd class="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
            <p v-for="contact in contacts" :key="contact.id">
              {{contact.poznam}}: <span class="font-medium">{{contact.popis}}</span>
            </p>
          </dd>
        </div>
        <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
          <dt class="font-medium text-primary">Váš Sommeliér</dt>
          <dd class="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">
            <p>{{ somelier.jmeno + ' ' + somelier.prijmeni }}</p>
            <p>{{ `Tel.: ${somelier.tel}, E-mail: ${somelier.email}` }}</p>
          </dd>
        </div>

      </dl>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheContacts"
}
</script>

<script setup>
import {computed} from "vue";
import {useStore} from "vuex";

const store = useStore()
const somelier = computed(() => store.state.company.zodpOsoba[0])
const companyLabels = computed(() => store.state.company.stitky)
const deliveryOptions = computed(() => store.getters.getDeliveryOptions)
const deliveryAddressLabels = computed(() => store.getters.getDeliveryAddress?.stitky)
const contacts = computed(() => store.getters.getContacts)

const delivery = computed(() => {
  const array = deliveryAddressLabels.value !== '' ? deliveryAddressLabels.value.split(', ') : companyLabels.value.split(', ')

  const deliveryDays = deliveryOptions.value.filter(option => {
    return array.some(label => label === option.kod)
  })

  return deliveryDays ?  sortDeliveryDays(deliveryDays) : []
})

const sortDeliveryDays = (deliveryOptions) => {
  return  deliveryOptions.sort(function(a, b) {
    const order = ['pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota', 'neděle'];
    const normalizedA = a.kod.toLowerCase().replace('_', '/');
    const normalizedB = b.kod.toLowerCase().replace('_', '/');
    return order.indexOf(normalizedA) - order.indexOf(normalizedB);
  });
}

</script>

<style scoped>

</style>