import {ref} from 'vue'
import apiClient from './apiClient'
import {useCookies} from "vue3-cookies";

const useOrder = () => {
    const error = ref(null)
    const loading = ref(false)

    /*get resource data*/
    const createOrder = async (order, email) => {
        error.value = null
        loading.value = true
        try {
            const login = useCookies().cookies.get('user')
            const res = await apiClient({'B2b-auth': login}).post('/order/create', {order: order, email: email})
            return res.data
        } catch (err) {
            error.value = err.message
        } finally {
            loading.value = false
        }
    }

    const updateOrder = async (order) => {
        error.value = null
        loading.value = true
        try {
            const login = useCookies().cookies.get('user')
            const res = await apiClient({'B2b-auth': login}).post('/order/update', {order: order})
            return res.data
        } catch (err) {
            error.value = err.message
        } finally {
            loading.value = false
        }
    }

    return {
        createOrder,
        updateOrder,
        error,
        loading,
    }
}

export default useOrder
