<template>
  <LoadingView v-if="loading"/>
  <RouterView v-else-if="company || hasToLogin"/>
</template>

<script>
import {useCookies} from "vue3-cookies";
import getCompany from "@/services/getCompany";
import {mapState} from "vuex";
import LoadingView from "@/components/LoadingView.vue";

export default {
  components: {LoadingView},
  setup() {
    const {cookies} = useCookies();
    const {fetchCompany, data, error, loading} = getCompany()

    return {cookies, fetchCompany, data, error, loading};
  },
  data() {
    return {
      hasToLogin : false
    }
  },
  computed: {
    ...mapState(['company', 'products'])
  },
  async created() {
    let user = this.cookies.get('user');

    if (user) {
      await this.fetchCompany(user)
      if (!this.error) {
        await this.$store.dispatch('setCompanyData', this.data)
      } else {
        this.hasToLogin = true
        this.$router.push('/login')
      }
    }else {
      this.hasToLogin = true
      this.$router.push('/login')
    }
  }
}
</script>
