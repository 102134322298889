<template>
  <main class="grid min-h-full place-items-center bg-white py-24 px-6 sm:py-32 lg:px-8">
    <div class="text-center">
      <p class="text-base font-semibold text-secondary">404</p>
      <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Stránka nenalezena</h1>
      <p class="mt-6 text-base leading-7 text-gray-600">Omlouváme se, ale stránka, kterou hledáte, již neexistuje nebo byla přesunuta.</p>
      <div class="mt-10 flex items-center justify-center gap-x-6">
        <router-link to="/" class="btn btn-secondary-light">Zpět domů</router-link>
        <a href="https://www.pelvins.cz/" class="text-sm font-semibold text-gray-900">Zpět na Pelvins.cz <span aria-hidden="true">&rarr;</span></a>
      </div>
    </div>
  </main>
</template>

<script setup></script>