import {createStore} from 'vuex'

function getPrice(product, VAT = true) {
    const basePrice = product.prodejCena
    const cenik = product.cenik[0]
    if (VAT) {
        if (cenik['typCenyDphK@showAs'] === 'bez DPH') {
            const priceDPH = basePrice * (1 + (Number(cenik.szbDph) / 100))
            return round(priceDPH)
        } else {
            return Number(basePrice);
        }
    } else {
        if (cenik['typCenyDphK@showAs'] === 'bez DPH') {
            return Number(basePrice);
        } else {
            const priceWithoutDPH = (1 / (1 + (Number(cenik.szbDph) / 100)) * basePrice)
            return round(priceWithoutDPH)
        }
    }
}

function getPackQuantity(labels) {
    const match = labels.match(/KARTON-(\d+)/)
    if (match) {
        return parseInt(match[1], 10)
    }
    return 1
}

const round = (number) => {
    return Math.round(number * 100) / 100
}

export default createStore({
    state: () => ({
        user: null,
        role: 'USER',
        company: null,
        contact: null,
        labels: null,
        products: null,
        order: {
            sumWithVat: 0,
            sumWithoutVat: 0
        },
    }),
    getters: {
        getDeliveryAddress: state => {
            if(state.company?.mistaUrceni.length) {
                return state.company.mistaUrceni.find(address => {
                    return address['kontaktOsoba'] === state.contact.id
                })
            }
            return null

        },
        getBanners: state => {
          return state.labels? state.labels?.filter(label => {
              const type = state.company.skupFir
              if(type === 'code:GASTRO') {
                  return label.skupVybKlic === 'code:BANERY GASTRO' && label.poznam !== ''
              } else {
                  return label.skupVybKlic === 'code:BANERY VINOTEKY' && label.poznam !== ''
              }
          }) : []
        },
        getDeliveryOptions: state => {
            return state.labels ?  state.labels?.filter(label => label.skupVybKlic === 'code:ZÁVOZNÍ DNY') : []
        },
        getContacts: state => {
            return state.labels ?  state.labels?.filter(label => label.skupVybKlic === 'code:B2B APLIKACE KONTAKT') : []
        },
        getDiscountPriceDescription: state => {
            return state.labels ? state.labels.find(label => label.kod === 'AKCNI SORTIMENT' )?.poznam : []
        },
        getSum: state => {
            let totalPrice = {
                withVat: 0,
                withoutVat: 0
            }

            for (const category in state.products) {
                const categoryProducts = state.products[category].products

                const categoryTotal = categoryProducts.reduce((total, product) => {
                    return {
                        totalWithVat : total.totalWithVat + product.priceWithVat * product.orderedQuantity,
                        totalWithoutVat: total.totalWithoutVat + product.priceWithoutVat * product.orderedQuantity,
                    }
                }, {totalWithVat: 0, totalWithoutVat: 0})

                totalPrice = {
                    withVat: round(totalPrice.withVat + categoryTotal.totalWithVat),
                    withoutVat: round(totalPrice.withoutVat + categoryTotal.totalWithoutVat)
                }
            }

            return totalPrice;
        },
    },
    mutations: {
        SET_COMPANY_DATA(state, data) {
            state.company = data
        },
        SET_CONTACT(state, data) {
            state.contact = data
            const labels = data.stitky.split(', ')
            state.role = labels.find(label => label === 'USER' || label === 'ADMIN') ?? 'USER'
        },
        SET_LABELS(state, data) {
            state.labels = data
        },
        //TODO: po schválení cen smazat
        // SET_PRODUCTS(state, data) {
        //     state.products =  data.odberatele.reduce((sortedProducts, product) => {
        //         const categoryName = product.poznam !== '' ? product.poznam : 'Vína Hrabal'
        //
        //         sortedProducts[categoryName] = sortedProducts[categoryName] || {
        //             name: categoryName,
        //             products: [],
        //         }
        //
        //         const cenik = product.cenik[0]
        //         sortedProducts[categoryName].products.push({
        //             category: categoryName,
        //             name: cenik.nazev,
        //             cenik: cenik.id,
        //             //TODO: cenik
        //             cenikCode: cenik.kod,
        //             vat: cenik.szbDph,
        //             imgSrc: cenik.techParam !== '' ? cenik.techParam : null,
        //             imgContent: cenik.prilohy.length === 1 ? cenik.prilohy[0].content : null,
        //             priceWithVat: getPrice(product, true),
        //             priceWithoutVat: getPrice(product, false),
        //             packageQuantity: getPackQuantity(cenik.stitky),
        //             orderedQuantity: 0
        //         })
        //         return sortedProducts
        //     }, {})
        // },
        SET_PRODUCTS(state, data) {
            state.products =  data.odberatele.reduce((sortedProducts, product) => {
                const categoryName = product.poznam !== '' ? product.poznam : 'Vína Hrabal'

                sortedProducts[categoryName] = sortedProducts[categoryName] || {
                    name: categoryName,
                    products: [],
                }

                const cenik = product.cenik[0]
                sortedProducts[categoryName].products.push({
                    category: categoryName,
                    name: product.nazev,
                    cenik: cenik.id,
                    cenikCode: product.kod,
                    vat: product.szbDph,
                    imgSrc: cenik.techParam !== '' ? cenik.techParam : null,
                    imgContent: cenik.prilohy.length === 1 ? cenik.prilohy[0].content : null,
                    priceWithVat: product.sumCelkem,
                    priceWithoutVat: product.sumZkl,
                    packageQuantity: getPackQuantity(cenik.stitky),
                    orderedQuantity: 0
                })
                return sortedProducts
            }, {})
        },
        SET_PRODUCTS_AT_DISCOUNT(state, data) {

            if(data['akcni-produkty'].length) {

                state.products['Akční sortiment'] = {
                    name: 'Akční sortiment',
                    products: data['akcni-produkty'].map(product => {
                        const cenik = product.cenik[0]
                        return {
                            category: 'Akční sortiment',
                            name: cenik.nazev,
                            cenik: cenik.id,
                            cenikKCode: cenik.kod,
                            imgSrc: cenik.techParam !== '' ? cenik.techParam : null,
                            imgContent: cenik.prilohy.length === 1 ? cenik.prilohy[0].content : null,
                            priceWithVat: getPrice(product, true),
                            priceWithoutVat: getPrice(product, false),
                            packageQuantity: getPackQuantity(cenik.stitky),
                            orderedQuantity: 0
                        }
                    })
                }
            }
        }
    },
    actions: {
        setCompanyData({commit}, data) {
            commit('SET_COMPANY_DATA', data.adresar)
            commit('SET_CONTACT', data.kontakt)
            commit('SET_LABELS', data.labels)
            commit('SET_PRODUCTS', data.adresar)
            commit('SET_PRODUCTS_AT_DISCOUNT', data.adresar)
        },
        resetProduct({commit, state}) {
            commit('SET_PRODUCTS', state.company)
            commit('SET_PRODUCTS_AT_DISCOUNT', state.company)
        }
    }
})