import { createApp } from 'vue'
import App from './App.vue'
import store from "@/store/store";
import router from './router'
import './assets/tailwind.css'
import * as Sentry from "@sentry/vue";

const app = createApp(App)

Sentry.init({
    app,
    dsn: "https://ad6770216012512e245e7d47e882b367@o136132.ingest.us.sentry.io/4508255721684992",
    integrations: [
        Sentry.replayIntegration(),
    ],
    authToken: import.meta.env.SENTRY_AUTH_TOKEN,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router).use(store)

app.mount('#app')
