<template>
  <AppLayout heading="Objednávka">
    <div class="grid sm:grid-cols-2 gap-10">
      <template v-for="(file) in files" :key="file.id">
        <div class="flex flex-1 justify-center overflow-hidden rounded-lg bg-white shadow">
          <a :href="'https://drive.google.com/thumbnail?id=' + file.poznam + '&sz=w1000'" target="_blank" class="px-4 py-3 max-h-[40rem]">
            <img :src="'https://drive.google.com/thumbnail?id=' + file.poznam + '&sz=w1000'" alt="" class="pointer-events-none group-hover:opacity-75 max-h-full max-w-full"/>
          </a>
        </div>
      </template>
    </div>
    <!--Contact info-->
    <TheContacts/>

    <template v-if="categoryProducts">
      <!--Products-->
      <template v-for="(category, categoryName) in categoryProducts" :key="categoryName">
        <div class="overflow-hidden rounded-lg bg-white shadow">
          <div class="px-4 py-5 sm:p-6">
            <ProductTable :category="categoryName" :data="category.products"/>
          </div>
        </div>
      </template>

      <!--Summary-->
      <div class="overflow-hidden rounded-lg shadow bg-white">
        <div class="px-4 py-5 sm:p-6">
          <div class="sm:flex-auto">
            <h1 class="text-xl font-semibold text-gray-900">Shrnutí</h1>
          </div>
          <!--Note-->
          <div class="mt-8">
            <label for="note" class="block text-sm font-medium">Poznámka k objednávce a dotazy </label>
            <div class="mt-1">
              <textarea id="note" v-model="note" rows="4" name="note" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"/>
            </div>
          </div>
          <p class="mt-4 font-medium">Platba: <span class="text-primary">{{ paymentMethod }}</span></p>
          <div v-if="isAdmin" class="flex justify-end mt-4">
            <table class=" divide-y divide-gray-300">
              <thead>
              <tr>
                <th scope="col" class="py-3.5 pl-6 pr-3 text-left text-sm font-normal sm:pl-0"></th>
                <th scope="col" class="py-3.5 px-3 text-right text-sm font-normal">Cena bez DPH</th>
                <th scope="col" class="py-3.5 pl-3 pr-6 text-right text-sm font-normal sm:pr-0">Cena s DPH</th>
              </tr>
              </thead>
              <tbody>
              <tr class="border-b border-gray-200 font-medium">
                <td class="py-4 sm:pr-16 text-sm sm:pl-0 whitespace-nowrap">Celkem Kč</td>
                <td class="py-4 pl-6 pr-3 text-sm sm:pl-0">{{ summary.withoutVat.toLocaleString() }}</td>
                <td class="py-4 pl-3 pr-6 text-sm sm:pr-0">{{ summary.withVat.toLocaleString() }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <!--BTN-->
          <div class="flex justify-center mt-8">
            <button type="button" :disabled="loading" class="inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2" @click="sendOrder">
              Závazně objednat
              <EnvelopeIcon v-show="!loading" class="ml-3 -mr-1 h-5 w-5" aria-hidden="true"/>
              <svg v-show="loading" class="ml-3 animate-spin mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </button>
          </div>

        </div>
      </div>
    </template>
    <template v-else>
      <!--TODO: Loading-->
      Loading
    </template>
  </AppLayout>
</template>

<script setup>
import TheContacts from "@/components/TheContacts.vue";
import ProductTable from "@/components/ProductTable.vue";
import {EnvelopeIcon} from '@heroicons/vue/20/solid'
import {computed, ref} from "vue";
import useOrder from "@/services/useOrder";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import AppLayout from "@/components/AppLayout.vue";
import {captureException, withScope} from "@sentry/vue";

const store = useStore()
const router = useRouter()
const {createOrder, updateOrder, error, loading} = useOrder()

const isAdmin = computed(() => store.state.role === 'ADMIN')
const note = ref(null)
const company = computed(() => store.state.company)
const categoryProducts = computed(() => {
  return store.state.products ? Object.fromEntries(Object.entries(store.state.products).sort()) : {}
})
const summary = computed(() => store.getters.getSum)
const deliveryAddress = computed(() => store.getters.getDeliveryAddress)

const paymentMethod = computed(() => {
  return company.value?.formaUhradyCis[0]?.nazev
})
const files = computed(() => store.getters.getBanners)
const getOrderedItems = () => {
  const items = []
  for (const category in categoryProducts.value) {
    categoryProducts.value[category].products.forEach(product => {
      if (product.orderedQuantity > 0) {
        if(product.category === 'Akční sortiment'){
          items.push({
            'cenik': product.cenik,
            'mnozMj': product.orderedQuantity,
            'szbDph' : product.vat,
            'cenaMj' : product.priceWithoutVat,
          })
        }else {
          items.push({
            'cenik': product.cenik,
            'mnozMj': product.orderedQuantity,
          })
        }

      }
    })
  }
  return items;
}
const sendOrder = async () => {
  const orderItems = getOrderedItems()

  if (orderItems.length > 0) {
    const order = {
      'typDokl': 'code:OBP',
      'firma': `code:${company.value.kod}`,
      'poznam': note.value,
      'polozkyDokladu': orderItems
    }

    deliveryAddress.value?.id ? order['mistUrc'] = deliveryAddress.value.id : ''

    const res = await createOrder(order, store.state.contact.email)

    if(error.value || res.success === 'false') {

      withScope((scope) => {
        scope.setExtra("errorMessage", res);
        scope.setExtra("order", order);
        captureException(new Error("Chyba vytvoření objendávky zákazníka" + company.value.kod ?? ''));
      });

      await router.push({name: 'Recapitulation', params: {status: 'error'}})
    } else {
      if(res.success === 'true'){
        const orderId = res.results[0].id
        await updateOrder([
          {
            'id': orderId,
            'varSym': orderId
          }
        ])
      }
      store.dispatch('resetProduct')
      await router.push({name: 'Recapitulation', params: {status: 'success'}})
    }
  }


}


</script>

