<template>
  <div class="min-h-full">
    <TheNavbar/>

    <div class="py-10 px-2">
      <header v-if="heading" class="mb-3">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl leading-tight tracking-tight text-gray-900">{{heading}}</h1>
        </div>
      </header>
      <main>
        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 space-y-5">
          <!-- content-->
          <slot></slot>
        </div>
      </main>
    </div>
    <footer>
      <div class="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div class="border-t border-gray-200 py-8 text-center text-sm text-gray-500">
          <span class="block sm:inline">PELVINS, s.r.o., Čejkovská 744, 691 02 Velké Bílovice</span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import TheNavbar from "@/components/TheNavbar.vue";
export default {
  name: "AppLayout",
  components: {TheNavbar},
  props: ['heading']
}
</script>

<script setup>

import {computed} from "vue";
import {useStore} from "vuex";

const store = useStore()
const company = computed(() => store.state.company)
</script>