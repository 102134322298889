<template>
  <AppLayout>
    <div class="h-[75vh]">
      <div class="init-center">
        <TheSpinner/>
        <p class="text-sm text-gray-500 tracking-wide">Načítání...</p>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import TheSpinner from "@/components/TheSpinner.vue";
import AppLayout from "@/components/AppLayout.vue";
export default {
  name: "LoadingView",
  components: {TheSpinner, AppLayout}
}
</script>

<style scoped>
.init-center {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}


</style>