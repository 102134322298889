<template>
  <div>
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">{{category}}</h1>
        <p v-if="category === 'Akční sortiment'" class="mt-1 text-sm text-gray-500">{{subheading}}</p>
      </div>
    </div>
    <!--<div class="-mx-6 mt-8 flow-root sm:mx-0 overflow-x-auto">-->
    <!--  <table class="min-w-full divide-y divide-gray-300">-->
    <!--    <thead class="">-->
    <!--    <tr>-->
    <!--      <th scope="col" class="py-3.5 pl-6 pr-3 text-left text-sm font-normal sm:pl-0">Produkt</th>-->
    <!--      <th scope="col" class="py-3.5 pl-6 pr-3 text-left text-sm font-normal sm:pl-0"></th>-->
    <!--      <th v-if="isAdmin" scope="col" class="py-3.5 px-3 text-right text-sm font-normal whitespace-nowrap">Cena za láhev bez DPH</th>-->
    <!--      <th v-if=isAdmin scope="col" class="py-3.5 px-3 text-right text-sm font-normal whitespace-nowrap">Cena za láhev s DPH</th>-->
    <!--      <th scope="col" class="py-3.5 pl-3 pr-6 text-right text-sm font-normal">Počet ks</th>-->
    <!--      <th scope="col" class="py-3.5 pl-3 pr-6 text-right text-sm font-normal sm:pr-0">Balení</th>-->
    <!--    </tr>-->
    <!--    </thead>-->
    <!--    <tbody>-->
    <!--      <tr v-for="product in data" :key="product.id" class="b order-b border-gray-200">-->
    <!--        <td class="py-4 pl-6 pr-3 text-sm sm:pl-0">-->
    <!--          <div class="font-medium text-gray-900 flex justify-center">-->
    <!--            <img :src="product.imgContent ? getProductImage(product.imgContent) : (product.imgSrc ??  placeholder)" :alt="product.name" class="max-h-32">-->
    <!--          </div>-->
    <!--        </td>-->
    <!--        <td class="py-4 pl-6 pr-3 text-sm sm:pl-0">-->
    <!--          <div class="font-medium text-gray-900">{{ product.name}}</div>-->
    <!--        </td>-->
    <!--        <td v-if=isAdmin class="py-4 px-3 text-right text-sm text-gray-500">{{ product.priceWithoutVat }}</td>-->
    <!--        <td v-if="isAdmin" class="py-4 px-3 text-right text-sm text-gray-500">{{ product.priceWithVat}}</td>-->
    <!--        <td class="py-4 pl-3 pr-6 text-right text-sm font-medium">-->
    <!--            <input v-model="product.orderedQuantity" type="number" name="quantity" id="quantity" min=0 max=100 class="w-20 rounded-md border-primary shadow-sm focus:border-primary focus:ring-primary sm:text-sm" placeholder="0 ks" />-->
    <!--        </td>-->
    <!--        <td class="py-4 pl-3 pr-6 text-center text-sm text-gray-500 sm:pr-0">-->
    <!--          {{product.packageQuantity}}-->
    <!--        </td>-->
    <!--    </tr>-->
    <!--    </tbody>-->
    <!--  </table>-->
    <!--</div>-->
    <div class="-mx-6 mt-8 flow-root sm:mx-0 overflow-x-auto">
      <table class="min-w-full divide-y divide-gray-300">
        <thead class="">
        <tr>
          <th scope="col" class="py-3.5 pl-6 pr-3 text-left text-sm font-normal sm:pl-0">Produkt</th>
          <th scope="col" class="hidden md:table-cell py-3.5 pl-6 pr-3 text-left text-sm font-normal sm:pl-0"></th>
          <th v-if="isAdmin" scope="col" class="hidden md:table-cell py-3.5 px-3 text-right text-sm font-normal whitespace-nowrap">Cena za láhev bez DPH</th>
          <th v-if=isAdmin scope="col" class="hidden md:table-cell py-3.5 px-3 text-right text-sm font-normal whitespace-nowrap">Cena za láhev s DPH</th>
          <th scope="col" class="py-3.5 pl-3 pr-6 text-right text-sm font-normal">Počet ks</th>
          <th scope="col" class="hidden md:table-cell py-3.5 pl-3 pr-6 text-right text-sm font-normal sm:pr-0">Balení</th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="product in data" :key="product.id" class="order-b order-gray-200 md:border-0">
            <td class="py-4 md:pl-6 pr-3 text-sm sm:pl-0">
              <div class="font-medium text-gray-900 flex justify-center">
                <img :src="product.imgContent ? getProductImage(product.imgContent) : (product.imgSrc ??  placeholder)" :alt="product.name" class="max-h-20 md:max-h-32">
              </div>
            </td>
            <td class="py-4 md:pl-6 pr-3 text-sm sm:pl-0">
              <div class="font-medium text-gray-900">{{ product.name}}</div>
              <div class="flex justify-between items-end md:hidden">
                <div class="mt-1 flex flex-col">
                  <div class="text-gray-500 mb-8">Balení {{product.packageQuantity}} KS</div>
                  <div class="grid grid-cols-2">
                    <span class="font-medium">{{ product.priceWithoutVat }} Kč </span>
                    <span class="text-gray-500">bez DPH/láhev</span>
                    <span class="font-medium">{{ product.priceWithVat}} Kč  </span>
                    <span class="text-gray-500">s DPH/láhev</span>
                  </div>
                </div>
                <input v-model="product.orderedQuantity" type="number" name="quantity" id="quantity" min=0 max=100 class="lg:w-20 rounded-md border-primary shadow-sm focus:border-primary focus:ring-primary sm:text-sm" placeholder="0 ks" />
              </div>

            </td>
            <td v-if=isAdmin class="hidden md:table-cell py-4 px-3 text-right text-sm text-gray-500">{{  product.priceWithoutVat.toLocaleString() }}</td>
            <td v-if="isAdmin" class="hidden md:table-cell py-4 px-3 text-right text-sm text-gray-500">{{ product.priceWithVat.toLocaleString()}}</td>
            <td class="hidden md:table-cell py-4 pl-3 pr-6 text-right text-sm font-medium">
                <input v-model="product.orderedQuantity" type="number" name="quantity" id="quantity" min=0 max=100 class="md:w-20 rounded-md border-primary shadow-sm focus:border-primary focus:ring-primary sm:text-sm" placeholder="0 ks" />
            </td>
            <td class="hidden md:table-cell py-4 pl-3 pr-6 md:text-center text-sm text-gray-500 sm:pr-0">
              <span>{{ product.packageQuantity }}</span>
            </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>

<script setup>
import placeholder from '@/assets/images/placeholder.png'
import {computed} from "vue";
import {useStore} from "vuex";

defineProps(['category', 'data'])

const store = useStore()
const isAdmin = computed(() => store.state.role === 'ADMIN')
const getProductImage = (base64) => {
  return `data:image/png;base64,${base64}`
}

const subheading = computed(() => store.getters.getDiscountPriceDescription)



</script>