<template>
  <Disclosure as="nav" class="border-b border-gray-200 bg-secondary-light" v-slot="{ open }">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="flex h-20 justify-between">
        <div class="flex">
          <div class="flex flex-shrink-0 items-center">
            <router-link :to="{name: 'Home'}">
              <img class="block h-12 w-auto md:hidden" src="@/assets/images/logo.svg" alt="Pelvins"/>
            </router-link>

            <div class="">
              <router-link :to="{name: 'Home'}">
                <img class="hidden h-14 w-auto md:block" src="@/assets/images/logo.svg" alt="Pelvins"/>
              </router-link>
            </div>
          </div>
        </div>
        <div v-if="company" class="hidden sm:ml-6 sm:flex sm:items-center">
          <!-- Profile dropdown -->
          <Menu as="div" class="relative ml-3">
            <div>
              <MenuButton
                  class="btn btn-primary">
                <span class="">{{user?.['kontaktOsoba@showAs'] ?? company.nazev}}</span>
              </MenuButton>
            </div>
            <transition enter-active-class="transition ease-out duration-200"
                        enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-from-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95">
              <MenuItems
                  class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItem>
                  <span class="block px-4 py-2 text-sm text-gray-700">
                    {{company.nazev}}
                  </span>
                </MenuItem>
                <MenuItem>
                  <button @click="logout" class="block px-4 py-2 text-sm flex justify-between text-primary font-medium">
                    <span> Odhlásit se</span>
                    <ArrowRightOnRectangleIcon class="h-5 w-5  ml-4"/>
                  </button>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
        <div v-if="company" class="-mr-2 flex items-center sm:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
              class="inline-flex items-center justify-center rounded-md bg-secondary-light p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true"/>
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true"/>
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden" v-if="company">
      <div class="border-t border-gray-200 pt-4 pb-3 bg-white">
        <div class="flex items-center px-4">
          <div class="">
            <div class="text-base font-medium text-gray-800">{{ user['kontaktOsoba@showAs'] }}</div>
            <div class="text-sm font-medium text-gray-500">{{ company.nazev }}</div>
          </div>
        </div>
        <div class="mt-3 space-y-1">
          <DisclosureButton @click="logout" class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 flex items-center">
            <span> Odhlásit se</span>
            <ArrowRightOnRectangleIcon class="h-5 w-5 ml-2"/>
          </DisclosureButton>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import {Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue'
import {Bars3Icon, XMarkIcon,ArrowRightOnRectangleIcon} from '@heroicons/vue/24/outline'
import {computed} from "vue";
import {useCookies} from "vue3-cookies";
import {useRouter} from "vue-router";
import {useStore} from "vuex";

const store = useStore()
const router = useRouter()
const { cookies } = useCookies();

const company = computed(() => store.state.company)
const user = computed(() => store.getters.getDeliveryAddress)

const logout = () => {
  cookies.remove("user")
  router.push({name: 'Login'})
}

// const user = {
//   name: 'Tom Cook',
//   email: 'tom@example.com',
//   imageUrl:
//       'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
// }
const navigation = [
  {name: 'Objednávka', href: '#', current: true},
]

</script>