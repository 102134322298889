<template>
  <div class="bg-white py-24 sm:py-32 lg:py-40">
    <div class="mx-auto max-w-5xl px-6 lg:px-8">
      <div class="sm:text-center">
        <CheckIcon class="h-14 w-14 text-secondary mx-auto mb-5 bg-secondary-light rounded-full p-2"/>
        <p class="mt-2 text-3xl font-medium">Děkujeme, objednávka byla úspěšně odeslána</p>
        <p class="mx-auto mt-3 max-w-2xl text-lg leading-8 text-gray-600">O termínu dodání Vás ještě budeme informovat.</p>
      </div>

      <div class="mt-10 max-w-lg sm:mx-auto md:max-w-none">
          <div class="flex flex-col sm:flex-row justify-center gap-6 ">
            <!--<a href="https://www.pelvins.cz/" class="btn btn-secondary-light">Zpět na web</a>-->
            <button @click="newOrder" type="button" class="btn btn-primary justify-center w-44">
              Nová objednávka
            </button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {CheckIcon} from "@heroicons/vue/20/solid";

export default {
  name: "RecapitulationSuccess",
  components: {CheckIcon}
}
</script>

<script setup>
import {useRouter} from "vue-router";

const router = useRouter()
const newOrder = () => {
  router.push({name: 'Home'})
}
</script>
