import { ref } from 'vue'
import apiClient from './apiClient'

const getCompany = () => {
    const error = ref(null)
    const loading = ref(false)
    const data = ref(null)

    /*get resource data*/
    const fetchCompany = async (login) => {
        error.value = null
        loading.value = true
        try {
            const result = await apiClient({'B2b-auth': login}).get('/company')
            data.value = result.data
        } catch (err) {
            error.value = err.message
        } finally {
            loading.value = false
        }
    }

    return {
        fetchCompany,
        data,
        error,
        loading,
    }
}

export default getCompany
