<template>
  <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-20 w-auto" src="@/assets/images/logo.svg" alt="Pelvins" />
      <h2 class="mt-6 text-center text-3xl font-medium tracking-tight text-gray-900">Přihlášení</h2>
      <p class="mt-2 text-center text-sm text-gray-600"></p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form @submit.prevent="login" class="space-y-6">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
            <div class="mt-1">
              <input id="email" v-model="email" name="email" type="email" autocomplete="email" required=""
                     :class="[error || companyError ? 'pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 border-0' : 'border']"
                     class="block w-full appearance-none rounded-md border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-primary sm:text-sm" />
            </div>
          </div>

          <div>
            <label for="password" class="block text-sm font-medium text-gray-700">Heslo</label>
            <div class="relative mt-1">
              <input id="password" v-model="password" name="password" type="password" autocomplete="current-password" required=""
                     :class="[error || companyError ? 'pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 border-0' : 'border']"
                     class="block  w-full appearance-none rounded-md border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary focus:outline-none focus:ring-primary sm:text-sm" />
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon v-if="error || companyError" class="h-5 w-5 text-red-500" aria-hidden="true" />
              </div>
            </div>
            <p v-if="error || companyError" class="mt-2 text-sm text-red-600" id="email-error">Chybné příhlašovací údaje</p>
          </div>

          <div>
            <button type="submit"
                    :disabled="loading"
                    class="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            >
              <svg v-show="loading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"  xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>Přihlásit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "LoginView"
}
</script>

<script setup>
import { ExclamationCircleIcon } from '@heroicons/vue/20/solid'
import {ref} from 'vue'
import {useRouter} from "vue-router";
import { useCookies } from "vue3-cookies";
import getCompany from "@/services/getCompany";
import {useStore} from "vuex";
import apiClient from "@/services/apiClient";

const router = useRouter()
const store = useStore()
const error = ref(null)
const { cookies } = useCookies();
const {fetchCompany, data, loading, error: companyError} = getCompany()


const email = ref(null)
const password = ref(null)

const login = async () => {
  error.value = null

  try {
    const response = await apiClient().post('/encryption-key', {
      login: `${email.value}:${password.value}`
    })

    const loginKey = response.data

    await fetchCompany(loginKey)

    if(!companyError.value){
      cookies.set('user', loginKey)
      await store.dispatch('setCompanyData', data.value)
      await router.push('/')
    }
  } catch (e) {
    error.value = e.message
  }
}

</script>