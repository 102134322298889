import axios from 'axios'

const apiClient = (
  header
) => {
  return axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {Accept: 'application/json', 'Content-Type': 'application/json', ...header},
    // headers: {
    //   Accept: 'application/json',
    //   'Content-Type': 'application/json',
    //   'B2b-auth' :'shean@shean.cz:password'
    // }
  })
}

export default apiClient
