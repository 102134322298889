<template>
  <div class="bg-white py-24 sm:py-32 lg:py-40">
    <div class="mx-auto max-w-5xl px-6 lg:px-8">
      <div class="sm:text-center">
        <ExclamationCircleIcon class="h-10 w-10 mx-auto text-red-900 mb-5"/>
        <p class="mt-2 text-3xl font-medium tracking-tight text-red-900 sm:text-4xl">Omlouváme se, zřejmě nastala chyba</p>
        <p class="mx-auto mt-3 max-w-2xl text-lg leading-8 text-gray-600">Při odesílání objednávky nastala chyba. Prosím, kontaktujte naše zákaznické centrum.</p>
      </div>

      <div class="mt-10 max-w-lg sm:mx-auto md:max-w-none">
        <div class="grid grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-12 md:gap-y-16 md:justify-items-center">
          <div  class="relative flex flex-col gap-6 sm:flex-row md:flex-col lg:flex-row">
            <div class="sm:min-w-0 sm:flex-1">
              <p class="text-lg font-semibold leading-8 text-primary">Zákaznické centrum</p>
              <p class="mt-2 text-base leading-7 text-gray-600">Tel.: +420 734 466 025</p>
              <p class="mt-2 text-base leading-7 text-gray-600">E-mail.: objednavky@pelvins.cz</p>
            </div>
          </div>
          <div  class="flex flex-col gap-6 grow-0  shrink-0 self-center max-w-[200px]">
            <!--<a href="https://www.pelvins.cz/" class="btn btn-secondary-light">Zpět na web</a>-->
            <button @click="newOrder" type="button" class="btn btn-primary w-44 justify-center">
              Nová objednávka
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecapitulationError"
}
</script>

<script setup>
import { ExclamationCircleIcon} from '@heroicons/vue/24/outline'
import {useRouter} from "vue-router";

const router = useRouter()
const newOrder = () => {
  router.push({name: 'Home'})
}
</script>


